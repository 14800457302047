import React from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'

const DeleteCollectQuestion = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const { oid, title } = props.data

  const handleOk = () => {
    axios.post('/p/collect/question/delete', { 
      merchant_oid: merchantOid, 
      question_oid: oid 
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('删除问题成功！')
          props.isCancel()
          props.getQuestionList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <Modal 
      title="删除问题"
      open={props.isOpen}
      onOk={handleOk}
      onCancel={props.isCancel}
      cancelText="取消"
      okType='danger'
      okText='删除'>

      <p>正在将问题
        <span style={{color: '#005dff'}}>《{title}》</span>
        从列表中移除，此操作不可逆，是否确认删除？
      </p>
    </Modal>
  )
}

export default DeleteCollectQuestion
