import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import AddCategory from './add'
import EditCategory from './edit'
import DeleteCategory from './delete'

export default function JobCategory() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [categoryList, setCategoryList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddCategoryVisible, setIsAddCategoryVisible] = useState(false)
  const [isEditCategoryVisible, setIsEditCategoryVisible] = useState(false)
  const [isDeleteCategoryVisible, setIsDeleteCategoryVisible] = useState(false)

  const getCategoryList = () => {
    axios.post('/p/jobs/category/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setCategoryList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getCategoryList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const editCategory = (oid) => {
    setIsEditCategoryVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteCategory = (oid, name) => {
    setIsDeleteCategoryVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">单位管理</div>
        </div>

        <div className='public-main-style'>
          <div className='public-main-style1'>
            <div className='public-main-style1-add' onClick={() => { setIsAddCategoryVisible(true) }}>
              <div className='public-main-style1-add-icon'>
                <MyIcon name='jiahao' />
              </div>
              <div className='public-main-style1-add-title'>创建新的职位分类</div>
            </div>

            {categoryList.map(category => (
              <div className='public-main-style1-content' key={category.oid} onClick={() => { editCategory(category.oid) }}>
                <div>
                  <div className='public-main-style1-content-title'>
                    <div className='public-main-style1-content-name'>{category.name}</div>
                    <div className='public-main-style1-content-delete' 
                      onClick={(event) => {event.stopPropagation(); deleteCategory(category.oid, category.name)}}>
                      <MyIcon name='huishouzhan' />
                    </div>
                  </div>
                </div>
                <div className='public-main-style1-content-other'>
                  <div className='public-main-style1-content-other-left'>
                    共发布了 {category.job_number} 个招聘职位
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isAddCategoryVisible && <AddCategory
        getCategoryList={getCategoryList} 
        isOpen={isAddCategoryVisible} 
        isCancel={() => { setIsAddCategoryVisible(false) }} 
      />}

      {isEditCategoryVisible && <EditCategory
        data={propsData}
        getCategoryList={getCategoryList}
        isOpen={isEditCategoryVisible}
        isCancel={() => { setIsEditCategoryVisible(false) }}
      />}

      {isDeleteCategoryVisible && <DeleteCategory
        data={propsData}
        getCategoryList={getCategoryList}
        isOpen={isDeleteCategoryVisible}
        isCancel={() => { setIsDeleteCategoryVisible(false) }}
      />}
    </div>
  )
}
