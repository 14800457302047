import React from 'react'
import { Radio } from 'antd'
import MyIcon from '../general/icon'

const MyRadio = ({
  id,
  required = false,
  label = '',
  value,
  onChange,
  errorMsg,
  options,
  defaultValue
}) => {

  return (
    <div className="form-box">
      <label htmlFor={id}>
        {label} 
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      <Radio.Group
        id={id}
        options={options}
        defaultValue={defaultValue}
        onChange={(e) => onChange(prevValues => ({ ...prevValues, [id]: e.target.value }))}
        value={value}
        optionType="button"
        buttonStyle="solid"
      />

      {errorMsg && (
        <div className="form-errormsg">
          <span><MyIcon name='jinggao' /></span> 
          <p>{errorMsg}</p>
        </div>
      )}
    </div>
  )
}

export default MyRadio
