import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyTextArea from '../../../components/forms/textArea'
import MyDatePicker from '../../../components/forms/datePicker'
import CollectFormDetail from './detail'

const AddCollectForm = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})
  const [propsData, setPropsData] = useState({})
  const [isFormDetailVisible, setIsFormDetailVisible] = useState(false)

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.title) {
      setErrorMsg({titleEmpty: '表单的标题不能为空'})
      return false
    }

    axios.post('/p/collect/forms/add', { 
      merchant_oid: merchantOid,
      title: formValue.title,
      description: formValue.description,
      result_prompt: formValue.result_prompt,
      start_time: formValue.start_time,
      end_time: formValue.end_time
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success(`《${formValue.title}》表单创建成功`)
          detailForm(response.data.oid)
          props.getFormList()
        } else {
          message.error(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  const detailForm = (oid) => {
    setIsFormDetailVisible(true)
    setPropsData({ oid: oid })
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="创建表单"
        width='600px'
        open={props.isOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        maskClosable={false}
        cancelText="取消"
        okText="创建">

        <MyInput
          id="title"
          required
          autoFocus
          maxLength={64}
          label='标题'
          placeholder='请输入表单的标题'
          value={formValue.title}
          onChange={setFormValue}
          errorMsg={errorMsg.titleEmpty}
        />

        <MyTextArea
          id="description"
          label='描述'
          minRows={3}
          placeholder='请输入表单的描述'
          value={formValue.description}
          onChange={setFormValue}
        />

        <div className='form-datePickers'>
          <MyDatePicker
            id="start_time"
            label='开始时间'
            placeholder='选择时间'
            value={formValue.start_time}
            onChange={setFormValue}
            styles={{width: '260px'}}
            showTime
          />

          <MyDatePicker
            id="end_time"
            label='结束时间'
            placeholder='选择时间'
            value={formValue.end_time}
            onChange={setFormValue}
            styles={{width: '260px'}}
            showTime
          />
        </div>

        <MyTextArea
          id="result_prompt"
          label='结束语'
          minRows={2}
          placeholder='填写者提交后看到的信息'
          value={formValue.result_prompt}
          onChange={setFormValue}
        />
      </Modal>

      {isFormDetailVisible && <CollectFormDetail
        data={propsData}
        getFormList={props.getFormList}
        isOpen={isFormDetailVisible}
        isCancel={() => { setIsFormDetailVisible(false); props.isCancel() }}
      />}
    </>
  )
}

export default AddCollectForm
