import { 
  RouterProvider, 
  createBrowserRouter, 
  redirect,
  Navigate
} from 'react-router-dom'
import Index from './pages/dashboard/index'
import Login from './pages/auth/login'
import Main from './pages/main'
import InfoNav from './pages/info'
import College from './pages/info/college'
import Major from './pages/info/major'
import Intro from './pages/info/intro'
import Campus from './pages/info/campus'
import Contact from './pages/info/contact'
import Plan from './pages/info/plan'
import Score from './pages/info/score'
import Inquiry from './pages/info/inquiry'
import CollectForms from './pages/info/forms'
import ContentNav from './pages/content'
import Column from './pages/content/column'
import Article from './pages/content/article'
import Picture from './pages/content/picture'
import Video from './pages/content/video'
import Character from './pages/content/character'
import Faq from './pages/content/faq'
import AuxiliaryNav from './pages/auxiliary'
import AdmissionNotice from './pages/auxiliary/admissionNotice'
import FacebodyCategory from './pages/auxiliary/facebodyCategory'
import FacebodyStudent from './pages/auxiliary/facebodyStudent'
import FreshmenDeferral from './pages/auxiliary/freshmenDeferral'
import FreshmenReadmission from './pages/auxiliary/freshmenReadmission'
import FreshmenConfig from './pages/auxiliary/freshmenConfig'
import ChatbotConfig from './pages/auxiliary/chatbotConfig'
import ChatbotFaq from './pages/auxiliary/chatbotFaq'
import ChatbotKnowledge from './pages/auxiliary/chatbotKnowledge'
import ChatbotRecord from './pages/auxiliary/chatbotRecord'
import MaterialNav from './pages/material'
import MiniappSlide from './pages/material/miniappSlide'
import MiniappNavigation from './pages/material/miniappNavigation'
import MiniappBanner from './pages/material/miniappBanner'
import WebsiteSlide from './pages/material/websiteSlide'
import WebsiteNavigation from './pages/material/websiteNavigation'
import WebsiteBanner from './pages/material/websiteBanner'
import SettingNav from './pages/setting'
import OperationLog from './pages/setting/operationLog'
import MoreNav from './pages/more'
import JobUnit from './pages/more/jobUnit'
import JobCategory from './pages/more/jobCategory'
import JobPost from './pages/more/jobPost'
import JobApplication from './pages/more/jobApplication'
import JobResume from './pages/more/jobResume'
import ResumePreview from './pages/more/jobResume/preview'
import NotFound from './pages/error/error404'
import Forbidden from './pages/error/error403'
import InternalServerError from './pages/error/error500'
import Maintenance from './pages/error/maintenance'

const interceptToken = () => {
  const token = localStorage.getItem('token')
  const merchant_oid = localStorage.getItem('merchant_oid')
  if (token && merchant_oid) {
    throw redirect('/index')
  }
  return null
}

const interceptNoToken = () => {
  const token = localStorage.getItem('token')
  const merchant_oid = localStorage.getItem('merchant_oid')
  if (!token || !merchant_oid) {
    throw redirect('/login')
  }
  return null
}

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
    loader: () => interceptToken()
  },
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: '',
        element: <Navigate to="index" />
      },
      {
        path: 'index',
        element: <Index />,
        loader: () => interceptNoToken()
      },
      {
        path: 'info',
        element: <InfoNav />,
        children: [
          {
            path: '',
            element: <Navigate to="college" />
          },
          {
            path: 'college',
            element: <College />,
            loader: () => interceptNoToken()
          },
          {
            path: 'major',
            element: <Major />,
            loader: () => interceptNoToken()
          },
          {
            path: 'intro',
            element: <Intro />,
            loader: () => interceptNoToken()
          },
          {
            path: 'campus',
            element: <Campus />,
            loader: () => interceptNoToken()
          },
          {
            path: 'contact',
            element: <Contact />,
            loader: () => interceptNoToken()
          },
          {
            path: 'forms',
            element: <CollectForms />,
            loader: () => interceptNoToken()
          },
          {
            path: 'plan',
            element: <Plan />,
            loader: () => interceptNoToken()
          },
          {
            path: 'score',
            element: <Score />,
            loader: () => interceptNoToken()
          },
          {
            path: 'inquiry',
            element: <Inquiry />,
            loader: () => interceptNoToken()
          },
        ]
      },
      {
        path: 'content',
        element: <ContentNav />,
        children: [
          {
            path: '',
            element: <Navigate to="article_column" />
          },
          {
            path: 'article_column',
            element: <Column />,
            loader: () => interceptNoToken()
          },
          {
            path: 'article_list',
            element: <Article />,
            loader: () => interceptNoToken()
          },
          {
            path: 'picture_column',
            element: <Column />,
            loader: () => interceptNoToken()
          },
          {
            path: 'picture_list',
            element: <Picture />,
            loader: () => interceptNoToken()
          },
          {
            path: 'video_column',
            element: <Column />,
            loader: () => interceptNoToken()
          },
          {
            path: 'video_list',
            element: <Video />,
            loader: () => interceptNoToken()
          },
          {
            path: 'character_column',
            element: <Column />,
            loader: () => interceptNoToken()
          },
          {
            path: 'character_list',
            element: <Character />,
            loader: () => interceptNoToken()
          },
          {
            path: 'faq_column',
            element: <Column />,
            loader: () => interceptNoToken()
          },
          {
            path: 'faq_list',
            element: <Faq />,
            loader: () => interceptNoToken()
          },
        ]
      },
      {
        path: 'auxiliary',
        element: <AuxiliaryNav />,
        children: [
          {
            path: '',
            element: <Navigate to="admission_notice" />
          },
          {
            path: 'admission_notice',
            element: <AdmissionNotice />,
            loader: () => interceptNoToken()
          },
          {
            path: 'facebody_category',
            element: <FacebodyCategory />,
            loader: () => interceptNoToken()
          },
          {
            path: 'facebody_student',
            element: <FacebodyStudent />,
            loader: () => interceptNoToken()
          },
          {
            path: 'freshmen_deferral',
            element: <FreshmenDeferral />,
            loader: () => interceptNoToken()
          },
          {
            path: 'freshmen_readmission',
            element: <FreshmenReadmission />,
            loader: () => interceptNoToken()
          },
          {
            path: 'freshmen_config',
            element: <FreshmenConfig />,
            loader: () => interceptNoToken()
          },
          {
            path: 'chatbot_knowledge',
            element: <ChatbotKnowledge />,
            loader: () => interceptNoToken()
          },
          {
            path: 'chatbot_config',
            element: <ChatbotConfig />,
            loader: () => interceptNoToken()
          },
          {
            path: 'chatbot_faq',
            element: <ChatbotFaq />,
            loader: () => interceptNoToken()
          },
          {
            path: 'chatbot_record',
            element: <ChatbotRecord />,
            loader: () => interceptNoToken()
          }
        ]
      },
      {
        path: 'material',
        element: <MaterialNav />,
        children: [
          {
            path: '',
            element: <Navigate to="slide/miniapp" />
          },
          {
            path: 'slide/miniapp',
            element: <MiniappSlide />,
            loader: () => interceptNoToken()
          },
          {
            path: 'navigation/miniapp',
            element: <MiniappNavigation />,
            loader: () => interceptNoToken()
          },
          {
            path: 'banner/miniapp',
            element: <MiniappBanner />,
            loader: () => interceptNoToken()
          },
          {
            path: 'slide/website',
            element: <WebsiteSlide />,
            loader: () => interceptNoToken()
          },
          {
            path: 'navigation/website',
            element: <WebsiteNavigation />,
            loader: () => interceptNoToken()
          },
          {
            path: 'banner/website',
            element: <WebsiteBanner />,
            loader: () => interceptNoToken()
          }
        ]
      },
      {
        path: 'setting',
        element: <SettingNav />,
        children: [
          {
            path: '',
            element: <Navigate to="logs" />
          },
          {
            path: 'logs',
            element: <OperationLog />,
            loader: () => interceptNoToken()
          }
        ]
      },
      {
        path: 'more',
        element: <MoreNav />,
        children: [
          {
            path: '',
            element: <Navigate to="job_unit" />
          },
          {
            path: 'job_unit',
            element: <JobUnit />,
            loader: () => interceptNoToken()
          },
          {
            path: 'job_category',
            element: <JobCategory />,
            loader: () => interceptNoToken()
          },
          {
            path: 'job_post',
            element: <JobPost />,
            loader: () => interceptNoToken()
          },
          {
            path: 'job_application',
            element: <JobApplication />,
            loader: () => interceptNoToken()
          },
          {
            path: 'job_resume',
            element: <JobResume />,
            loader: () => interceptNoToken()
          }
        ]
      },
    ]
  },
  {
    path: 'resume/preview/:oid',
    element: <ResumePreview />
  },
  {
    path: '/403',
    element: <Forbidden />
  },
  {
    path: '/500',
    element: <InternalServerError />
  },
  {
    path: '/maintenance',
    element: <Maintenance />
  },
  {
    path: '/*',
    element: <NotFound />
  }
])

export default function Router() {
  return (
    <RouterProvider router={router} />
  )
}
