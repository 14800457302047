import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'
import MyIcon from '../../components/general/icon'

export default function InfoNav() {

  const computedClassName = ({ isActive }) => {
    return isActive ?  "secondaryNav-menu secondaryNav-menuActive" : "secondaryNav-menu"
  }

  return (
    <div className="secondaryNav-page">
      <div className="secondaryNav-container">
        <div className="secondaryNav-headline">招生信息</div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">院系专业</div>
            <NavLink to="college" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='yuanxi' /></div>
              <div className="secondaryNav-name">院系部门</div>
            </NavLink>
            <NavLink to="major" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='zhuanye' /></div>
              <div className="secondaryNav-name">招生专业</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">学校概况</div>
            <NavLink to="intro" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='jianjie' /></div>
              <div className="secondaryNav-name">学校简介</div>
            </NavLink>
            <NavLink to="campus" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='xiaoqu' /></div>
              <div className="secondaryNav-name">校区位置</div>
            </NavLink>
            <NavLink to="contact" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='tongxun' /></div>
              <div className="secondaryNav-name">联系信息</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">表单收集</div>
            <NavLink to="forms" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='shoujibiaodan' /></div>
              <div className="secondaryNav-name">报名表单</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">信息与查询</div>
            <NavLink to="plan" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='jihua' /></div>
              <div className="secondaryNav-name">招生计划</div>
            </NavLink>
            <NavLink to="score" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='fenshu' /></div>
              <div className="secondaryNav-name">历年分数</div>
            </NavLink>
            <NavLink to="inquiry" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='chaxun' /></div>
              <div className="secondaryNav-name">录取查询</div>
            </NavLink>
          </div>
      </div>
      <Outlet />
    </div>
  )
}
