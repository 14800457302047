import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import AddFacebodyCategory from './add'
import EditFacebodyCategory from './edit'
import DeleteFacebodyCategory from './delete'
import MyQrcode from '../../../components/general/qrcode'

export default function FacebodyCategory() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [facebodyCategoryList, setFacebodyCategoryList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddFacebodyCategoryVisible, setIsAddFacebodyCategoryVisible] = useState(false)
  const [isEditFacebodyCategoryVisible, setIsEditFacebodyCategoryVisible] = useState(false)
  const [isDeleteFacebodyCategoryVisible, setIsDeleteFacebodyCategoryVisible] = useState(false)
  const [isFacebodyCategoryAddressVisible, setIsFacebodyCategoryAddressVisible] = useState(false)

  const getFacebodyCategoryList = () => {
    axios.post('/p/facebody/category/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setFacebodyCategoryList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getFacebodyCategoryList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const editFacebodyCategory = (oid) => {
    setIsEditFacebodyCategoryVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteFacebodyCategory = (oid, name) => {
    setIsDeleteFacebodyCategoryVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  const previewFacebodyCategoryAddress = (oid) => {
    setIsFacebodyCategoryAddressVisible(true)
    setPropsData({ accessAddress: 'https://facebody.yunzhaosoft.com/login/'+oid})
  }

  const getStatusColor = (code) => {
    switch (code) {
      case 1:
        return 'orange'
      case 2:
        return 'blue'
      case 3:
        return 'grey'
      default:
        return 'green'
    }
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">分类管理</div>
        </div>

        <div className='public-main-style'>
          <div className='public-main-style1'>
            <div className='public-main-style1-add' onClick={() => { setIsAddFacebodyCategoryVisible(true) }}>
              <div className='public-main-style1-add-icon'>
                <MyIcon name='jiahao' />
              </div>
              <div className='public-main-style1-add-title'>创建新的人脸核身分类</div>
            </div>

            {facebodyCategoryList.map(item => (
              <div className='public-main-style1-content' key={item.oid} onClick={() => { editFacebodyCategory(item.oid) }}>
                <div>
                  <div className='public-main-style1-content-title'>
                    <div className='public-main-style1-content-name'>{item.name}</div>
                    <div className='public-main-style1-content-delete' 
                      onClick={(event) => {event.stopPropagation(); deleteFacebodyCategory(item.oid, item.name)}}>
                      <MyIcon name='huishouzhan' />
                    </div>
                  </div>
                  <div className='public-main-style1-content-description'>
                    {item.descrption ? item.descrption : '暂无分类描述'}
                  </div>
                </div>
                <div className='public-main-style1-content-other'>
                  <div className='public-main-style1-content-other-left'>
                    <div style={{width: '7px', height: '7px', borderRadius: '5px', background: getStatusColor(item.status.code)}}></div>
                    {item.status.msg}
                  </div>
                  <div className='public-main-style1-content-other-right' onClick={(event) => {event.stopPropagation(); previewFacebodyCategoryAddress(item.oid)}}>
                    访问地址
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isAddFacebodyCategoryVisible && <AddFacebodyCategory
        getFacebodyCategoryList={getFacebodyCategoryList} 
        isOpen={isAddFacebodyCategoryVisible} 
        isCancel={() => { setIsAddFacebodyCategoryVisible(false) }} 
      />}

      {isEditFacebodyCategoryVisible && <EditFacebodyCategory
        data={propsData}
        getFacebodyCategoryList={getFacebodyCategoryList}
        isOpen={isEditFacebodyCategoryVisible}
        isCancel={() => { setIsEditFacebodyCategoryVisible(false) }}
      />}

      {isDeleteFacebodyCategoryVisible && <DeleteFacebodyCategory
        data={propsData}
        getFacebodyCategoryList={getFacebodyCategoryList}
        isOpen={isDeleteFacebodyCategoryVisible}
        isCancel={() => { setIsDeleteFacebodyCategoryVisible(false) }}
      />}

      {isFacebodyCategoryAddressVisible && <MyQrcode
        data={propsData}
        isOpen={isFacebodyCategoryAddressVisible}
        isCancel={() => { setIsFacebodyCategoryAddressVisible(false) }}
      />}
    </div>
  )
}
