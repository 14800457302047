import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message, Upload, Spin } from 'antd'
import MyIcon from '../../../components/general/icon'
import { apiBaseURL } from '../../../request'

const ImportFacebodyStudent = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [isLoading, setIsLoading] = useState(false)

  const imageUpload = {
    name: 'doc',
    action: `${apiBaseURL}/common/doc/upload`,
    headers: {
      authorization: localStorage.getItem('token'),
    },
    onChange(info) {
      if (info.file.status === 'done') {
        handleImportInfo(info.file.response.data.doc_url)
      }
    },
  }

  const fileUpload = {
    name: 'file',
    action: `${apiBaseURL}/common/file/upload`,
    headers: {
      authorization: localStorage.getItem('token'),
    },
    onChange(info) {
      setIsLoading(true)
      if (info.file.status === 'done') {
        setIsLoading(false)
        handleImportPhoto(info.file.response.data.filename)
      }
    },
  }

  const handleImportInfo = (filename) => {
    setIsLoading(true)
    axios.post('/p/facebody/student/info/import', { 
      merchant_oid: merchantOid, 
      facebody_category_oid: props.filterValue.category,
      filename: filename
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('人脸核身学生数据导入成功！')
          setIsLoading(false)
          props.isCancel()
          props.getStudentData()
        } else {
          message.error(response.msg)
          setIsLoading(false)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleImportPhoto = (filename) => {
    setIsLoading(true)
    axios.post('/p/facebody/student/photo/import', { 
      merchant_oid: merchantOid, 
      facebody_category_oid: props.filterValue.category,
      filename: filename
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('人脸核身学生照片导入成功！')
          setIsLoading(false)
          props.isCancel()
          props.getStudentData()
        } else {
          message.error(response.msg)
          setIsLoading(false)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleDownload = () => {
    setIsLoading(true)
    axios.post('/common/excel_template/download', { 
      name: 'rlhs'
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('人脸核身学生信息导入模版下载成功！')
          setIsLoading(false)
          const link = document.createElement('a')
          link.href = response.data.download_address
          link.setAttribute('download', '')
          link.click()
          document.body.removeChild(link)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <>
      <Modal
        width={700}
        title="人脸核身学生数据导入"
        open={props.isOpen}
        onCancel={props.isCancel}
        maskClosable={false}
        footer=''
      >

        <Spin spinning={isLoading}>
          <div className='public-import-container'>
            <div className='public-import-box'>
              <div className='public-import-icon'><MyIcon name='daoru' /></div>
              <div className='public-import-title'>第1步：导入学生信息</div>
              <div className='public-import-despcription'>导入数据前请先下载导入模版，根据模版格式导入，支持自定义字段</div>

              <Upload {...imageUpload} showUploadList={false}>
                <div className='public-import-button1'>
                  <p>导入信息</p>
                </div>
              </Upload>

              <div className='public-import-button2' onClick={() => { handleDownload() }}>
                <p>下载模版</p>
              </div>
            </div>

            <div className='public-import-box'>
              <div className='public-import-icon'><MyIcon  name='daoru' /></div>
              <div className='public-import-title'>第2步：导入学生照片</div>
              <div className='public-import-despcription'>仅支持zip、rar格式的压缩文件；照片支持以身份证号或考生号命名</div>

              <Upload {...fileUpload} showUploadList={false}>
                <div className='public-import-button1'>
                  <p>导入照片</p>
                </div>
              </Upload>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  )
}

export default ImportFacebodyStudent
