import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import AddCollectForm from './add'
import CollectFormDetail from './detail'
import DeleteCollectForm from './delete'
import CollectData from './data'
import MyQrcode from '../../../components/general/qrcode'

export default function CollectForms() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formList, setFormList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddFormVisible, setIsAddFormVisible] = useState(false)
  const [isFormDetailVisible, setIsFormDetailVisible] = useState(false)
  const [isDeleteFormVisible, setIsDeleteFormVisible] = useState(false)
  const [isCollectDataVisible, setIsCollectDataVisible] = useState(false)
  const [isFormAddressVisible, setIsFormAddressVisible] = useState(false)

  const getFormList = () => {
    axios.post('/p/collect/forms/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getFormList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const detailForm = (oid) => {
    setIsFormDetailVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteForm = (oid, title) => {
    setIsDeleteFormVisible(true)
    setPropsData({ oid: oid, title: title})
  }

  const viewCollectData = (oid) => {
    setIsCollectDataVisible(true)
    setPropsData({ oid: oid})
  }

  const viewFormAddress = (oid) => {
    setIsFormAddressVisible(true)
    const baseUrl = process.env.NODE_ENV === 'development' 
        ? 'http://localhost:3004/' 
        : 'https://forms.yunzhaosoft.com/';
    setPropsData({ accessAddress: baseUrl + oid})
  }

  const getStatusColor = (code) => {
    switch (code) {
      case 1:
        return 'orange'
      case 2:
        return 'blue'
      case 3:
        return 'grey'
      default:
        return 'green'
    }
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">表单列表</div>
        </div>

        <div className='public-main-style'>
          <div className='public-main-style1'>
            <div className='public-main-style1-add' onClick={() => { setIsAddFormVisible(true) }}>
              <div className='public-main-style1-add-icon'>
                <MyIcon name='jiahao' />
              </div>
              <div className='public-main-style1-add-title'>创建新的表单</div>
            </div>

            {formList.map(item => (
              <div className='public-main-style1-content' key={item.oid} onClick={() => { detailForm(item.oid) }}>
                <div>
                  <div className='public-main-style1-content-title'>
                    <div className='public-main-style1-content-name'>{item.title}</div>
                    <div className='public-main-style1-content-delete' 
                      onClick={(event) => {event.stopPropagation(); deleteForm(item.oid, item.title)}}>
                      <MyIcon name='huishouzhan' />
                    </div>
                  </div>
                  <div className='public-main-style1-content-description'>
                    {item.description ? item.description : '暂无表单描述'}
                  </div>
                </div>
                <div className='public-main-style1-content-other'>
                  <div className='public-main-style1-content-other-left'>
                    <div style={{width: '7px', height: '7px', borderRadius: '5px', background: getStatusColor(item.status.code)}}></div>
                    {item.status.msg}
                  </div>
                  <div className='public-main-style1-content-other-rights'>
                    <div className='public-main-style1-content-other-right'
                      onClick={(event) => {event.stopPropagation(); viewFormAddress(item.oid)}}>
                      访问地址
                    </div>
                    <div className='public-main-style1-content-other-right'
                      onClick={(event) => {event.stopPropagation(); viewCollectData(item.oid)}}>
                      收集数据
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isAddFormVisible && <AddCollectForm
        getFormList={getFormList} 
        isOpen={isAddFormVisible} 
        isCancel={() => { setIsAddFormVisible(false) }} 
      />}

      {isFormDetailVisible && <CollectFormDetail
        data={propsData}
        getFormList={getFormList}
        isOpen={isFormDetailVisible}
        isCancel={() => { setIsFormDetailVisible(false) }}
      />}

      {isDeleteFormVisible && <DeleteCollectForm
        data={propsData}
        getFormList={getFormList}
        isOpen={isDeleteFormVisible}
        isCancel={() => { setIsDeleteFormVisible(false) }}
      />}

      {isCollectDataVisible && <CollectData
        data={propsData}
        isOpen={isCollectDataVisible}
        isCancel={() => { setIsCollectDataVisible(false) }}
      />}

      {isFormAddressVisible && <MyQrcode
        data={propsData}
        isOpen={isFormAddressVisible}
        isCancel={() => { setIsFormAddressVisible(false) }}
      />}
    </div>
  )
}
