import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, Input, Select } from 'antd'
import EditCollectForm from './edit'
import AddCollectQuestion from './question/add'
import DeleteCollectQuestion from './question/delete'
import EditCollectQuestion from './question/edit'
import MyIcon from '../../../components/general/icon'

const CollectFormDetail = (props) => {
  const { TextArea } = Input
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formDetail, setFormDetail] = useState({})
  const [questionList, setQuestionList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isEditFormVisible, setIsEditFormVisible] = useState(false)
  const [isAddQuesitonVisible, setIsAddQuesitonVisible] = useState(false)
  const [isEditQuesitonVisible, setIsEditQuesitonVisible] = useState(false)
  const [isDeleteQuesitonVisible, setIsDeleteQuesitonVisible] = useState(false)

  const getFormDetail = () => {
    axios.post('/p/collect/forms/detail/get', {
      merchant_oid: merchantOid,
      form_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormDetail(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const getQuestionList = () => {
    axios.post('/p/collect/question/list/get', {
      merchant_oid: merchantOid,
      form_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setQuestionList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getFormDetail()
    getQuestionList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, props.data.oid])

  const handleCancel = () => {
    props.isCancel()
  }

  const editForm = () => {
    setIsEditFormVisible(true)
    setPropsData({ oid: props.data.oid })
  }

  const addQuestion = () => {
    setIsAddQuesitonVisible(true)
    setPropsData({ oid: props.data.oid })
  }

  const editQuestion = (oid) => {
    setIsEditQuesitonVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteQuestion = (oid, title) => {
    setIsDeleteQuesitonVisible(true)
    setPropsData({ oid: oid, title: title})
  }

  return (
    <>
      <Modal 
        title={formDetail.title}
        destroyOnClose={true}
        width='700px'
        open={props.isOpen}
        onCancel={handleCancel}
        footer={false}>

        <div className='forms-collect-container'>
          <div className='forms-collect-name' onClick={editForm}>
            <div className='forms-collect-title'>{formDetail.title}</div>
            <div className='forms-collect-description'>{formDetail.description ? formDetail.description : '暂无表单描述'}</div>
          </div>

          {questionList.length > 0 && questionList.map((question, index) => (
            <div className='forms-collect-item' key={question.oid}>
              <div className='forms-collect-item-required'>{question.is_required ? '*' : ''}</div>
              <div className='forms-collect-item-serialNumber'>{`${String(index + 1).padStart(2, '0')}`}</div>

              <div className='forms-collect-item-question' onClick={() => { editQuestion(question.oid) }}>
                <div className='forms-collect-item-title'>
                  <span>{question.title}</span>
                  <div className='forms-collect-item-delete' onClick={(event) => {event.stopPropagation(); deleteQuestion(question.oid, question.title)}}>
                    <MyIcon name='huishouzhan' />
                  </div>
                </div>
                <div className='forms-collect-item-description'>{question.description}</div>
                {question.type === 1 && 
                  <Input
                    disabled
                    placeholder='用户在此输入'
                  />
                }
                {question.type === 2 && 
                  <TextArea
                    disabled
                    placeholder='用户在此输入'
                  />
                }
                {([3, 4, 5].includes(question.type) && question.options.length > 0) && question.options.map((option, optionIndex) => (
                  <div className='forms-collect-item-select' key={option.oid}>
                    {question.type === 3 && <MyIcon name='danxuankuang-moren' />}
                    {question.type === 4 && <MyIcon name='fuxuankuang-moren' />}
                    {question.type === 5 && `${optionIndex + 1}. `}
                    <span>{option.value}</span>
                  </div>
                ))}
                {question.type === 6 && 
                  <Select
                    disabled
                    placeholder={`用户在此选择 ${question.region_value}`}
                  />
                }
                {question.type === 7 && 
                  <div className='forms-collect-item-upload'>
                    <MyIcon name='jiahao' />
                    <p>用户在此处上传图片</p>
                  </div>
                }
              </div>
            </div>
          ))}

          <div className='forms-collect-button' onClick={addQuestion}>添加问题</div>
        </div>
      </Modal>

      {isEditFormVisible && <EditCollectForm
        data={propsData}
        getFormDetail={getFormDetail} 
        getFormList={props.getFormList} 
        isOpen={isEditFormVisible} 
        isCancel={() => { setIsEditFormVisible(false) }} 
      />}

      {isAddQuesitonVisible && <AddCollectQuestion
        data={propsData}
        getQuestionList={getQuestionList}
        isOpen={isAddQuesitonVisible} 
        isCancel={() => { setIsAddQuesitonVisible(false) }} 
      />}

      {isEditQuesitonVisible && <EditCollectQuestion
        data={propsData}
        getQuestionList={getQuestionList}
        isOpen={isEditQuesitonVisible}
        isCancel={() => { setIsEditQuesitonVisible(false) }}
      />}

      {isDeleteQuesitonVisible && <DeleteCollectQuestion
        data={propsData}
        getQuestionList={getQuestionList}
        isOpen={isDeleteQuesitonVisible}
        isCancel={() => { setIsDeleteQuesitonVisible(false) }}
      />}
    </>
  )
}

export default CollectFormDetail
