import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Image } from 'antd'
import BasicFilter from '../../../components/filter/basicFilter'
import MyIcon from '../../../components/general/icon'
import AddBanner from './add'
import EditBanner from './edit'
import DeleteBanner from './delete'

export default function MiniappBanner() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [propsData, setPropsData] = useState({})
  const [bannerList, setBannerList] = useState([])
  const [isAddBannerVisible, setIsAddBannerVisible] = useState(false)
  const [isEditBannerVisible, setIsEditBannerVisible] = useState(false)
  const [isDeleteBannerVisible, setIsDeleteBannerVisible] = useState(false)

  const initialConditions = {
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getBannerList = () => {
    axios.post('/p/deco/banner/list/get', { 
      merchant_oid: merchantOid,
      source: 1
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setBannerList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getBannerList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue])

  const editBanner = (oid) => {
    setIsEditBannerVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteBanner = (oid, title) => {
    setIsDeleteBannerVisible(true)
    setPropsData({ oid: oid, title: title})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">临时广告位</div>
          <BasicFilter
            addName='横幅广告'
            filterValue={filterValue} 
            setFilterValue={setFilterValue} 
            isAddVisible={() => { setIsAddBannerVisible(true) }}  
          />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th style={{width: '300px'}}>
                    <div className='public-main-style2-title'>横幅广告</div>
                  </th>
                  <th>打开模式</th>
                  <th>是否隐藏</th>
                  <th>排序</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {bannerList && (bannerList.length > 0 ? (bannerList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editBanner(item.oid) }}>
                    <td style={{width: '300px'}}>
                      <div className='public-main-style2-tuwen'>
                        <div style={{margin: '10px 15px 10px 0'}} onClick={(event) => {event.stopPropagation()}}>
                          <Image src={item.thumbnail} width={100} height={80} />
                        </div>
                        <span>{item.title}</span>
                      </div>
                    </td>
                    <td>{item.mode}</td>
                    <td>{item.is_hide === 1 ? '是' : '否'}</td>
                    <td>{item.sort}</td>
                    <td style={{width: '50px'}}>
                      <div className='public-main-style2-operation-box'>
                        <div className='public-main-style2-operation' 
                          onClick={(event) => {event.stopPropagation(); deleteBanner(item.oid, item.title)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan='4'><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isAddBannerVisible && <AddBanner
        getBannerList={getBannerList} 
        isOpen={isAddBannerVisible} 
        isCancel={() => { setIsAddBannerVisible(false) }} 
      />}

      {isEditBannerVisible && <EditBanner
        data={propsData}
        getBannerList={getBannerList} 
        isOpen={isEditBannerVisible} 
        isCancel={() => { setIsEditBannerVisible(false) }} 
      />}

      {isDeleteBannerVisible && <DeleteBanner
        data={propsData}
        getBannerList={getBannerList} 
        isOpen={isDeleteBannerVisible} 
        isCancel={() => { setIsDeleteBannerVisible(false) }} 
      />}
    </div>
  )
}
