import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, Pagination, Spin, Tooltip, Image } from 'antd'
import ExportFormData from './export'
import MyDatePicker from '../../../components/forms/datePicker'

const CollectData = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [collectData, setCollectData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [isExportVisible, setIsExportVisible] = useState(false)
  const [propsData, setPropsData] = useState({})
  const [filterValue, setFilterValue] = useState({})

  const getCollectData = () => {
    axios.post('/p/collect/data/get', { 
      merchant_oid: merchantOid,
      form_oid: props.data.oid,
      start_time: filterValue.start_time,
      end_time: filterValue.end_time,
      page: currentPage, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setIsLoading(false)
          setCollectData({
            'total_page': response.data.total_page,
            'current_page': response.data.current_page,
            'total': response.data.total,
            'thead': response.data.thead,
            'tbody': response.data.tbody
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getCollectData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, props.data.oid, currentPage, filterValue])

  const handleExport = (oid) => {
    setIsExportVisible(true)
    setPropsData({ oid: oid})
  }

  return (
    <>
      <Modal 
        title='收集数据'
        destroyOnClose={true}
        width='1200px'
        open={props.isOpen}
        onCancel={props.isCancel}
        footer={false}>

        <Spin spinning={isLoading}>
          <div className='public-filter'>
            <div className='public-filter-button' onClick={() => { handleExport(props.data.oid) }}>导出</div>
            <div className='public-filter-datePicker'>
              <MyDatePicker
                id="start_time"
                placeholder='选择结束时间'
                value={filterValue.start_time}
                onChange={setFilterValue}
                styles={{width: '200px'}}
                showTime
              />
            </div>
            <div className='public-filter-datePicker'>
              <MyDatePicker
                id="end_time"
                placeholder='选择开始时间'
                value={filterValue.end_time}
                onChange={setFilterValue}
                styles={{width: '200px'}}
                showTime
              />
            </div>
          </div>

          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  {collectData.thead && collectData.thead.map((head, index) => (
                    <th key={head} style={index === 0 ? {textAlign: 'left', paddingLeft: '20px', paddingRight: '50px'} : {paddingRight: '50px'}}>
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {collectData.tbody && (collectData.tbody.length > 0 ? (collectData.tbody.map((body) => (
                  <tr className='public-main-style2-tbody2' key={body.oid}>
                    <td style={{textAlign: 'left', paddingLeft: '20px', paddingRight: '50px'}}>{body.submit_time}</td>
                    {collectData.thead.map((header, index) => (index >= 1 ? (
                      <td style={{ paddingRight: '50px', maxWidth: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {Array.isArray(body.answers[header]) && body.answers[header].length > 0 ? (
                          body.answers[header][1] === 7 ? (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                              {body.answers[header][0].map((imageUrl) => (
                                <Image src={imageUrl} width={40} height={40}/>
                              ))}
                            </div>
                          ) : (
                            <Tooltip key={index} placement="right" color='black' title={body.answers[header]}>
                            {body.answers[header][0]}
                            </Tooltip>
                          )
                        ) : (
                          '-'
                        )}
                      </td>) : null
                    ))}
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan={collectData.thead.length}><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='pagination'>
            <Pagination 
              current={currentPage}
              total={collectData.total}
              pageSize={20}
              onChange={(page) => setCurrentPage(page)}
              showTotal={(total) => `共${total}条`}
              showSizeChanger={false}
              hideOnSinglePage={true}
              showQuickJumper={true}
            />
          </div>
        </Spin>
      </Modal>

      {isExportVisible && <ExportFormData
        data={propsData}
        filterValue={filterValue}
        isOpen={isExportVisible}
        isCancel={() => { setIsExportVisible(false) }}
      />}
    </>
  )
}

export default CollectData
