import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../../components/forms/input'
import MyTextArea from '../../../../components/forms/textArea'
import MySwitch from '../../../../components/forms/switch'
import MyRadio from '../../../../components/forms/radio'
import MyIcon from '../../../../components/general/icon'

const EditCollectQuestion = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [options, setOptions] = useState([{ sort: 1, value: '', oid: '' }, { sort: 2, value: '', oid: '' }])
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/collect/question/detail/get', {
      merchant_oid: merchantOid,
      question_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
          setOptions(response.data.options)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data.oid])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.title) {
      setErrorMsg({titleEmpty: '问题的标题不能为空'})
      return false
    }
    if ([3, 4, 5].includes(formValue.question_type)) {
      if (options.length < 2) {
        setErrorMsg({ optionsEmpty: '选择题至少需要两个选项' });
        return false;
      }
      for (let option of options) {
        if (!option.value) {
          setErrorMsg({ optionsEmpty: '选项的值不能为空' });
          return false;
        }
      }
    }

    axios.post('/p/collect/question/edit', { 
      merchant_oid: merchantOid,
      question_oid: props.data.oid,
      title: formValue.title,
      description: formValue.description,
      question_type: formValue.question_type,
      is_required: formValue.is_required,
      text_check: formValue.text_check,
      region_level: formValue.region_level,
      options: options,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          message.success('问题修改成功！')
          props.getQuestionList()
        } else {
          message.error(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  const handleAddOption = () => {
    const newSort = options.length > 0 ? Math.max(...options.map(option => option.sort)) + 1 : 1
    const newOption = { sort: newSort, value: '' }
    setOptions([...options, newOption])
  }

  const handleOptionChange = (sort, value) => {
    const updatedOptions = options.map((option) =>
      option.sort === sort ? { ...option, value } : option
    )
    setOptions(updatedOptions)
  }

  const handleRemoveOption = (sort) => {
    const updatedOptions = options.filter((option) => option.sort !== sort)
    setOptions(updatedOptions)
  }

  return (
    <Modal
      destroyOnClose={true}
      title="编辑问题"
      width='600px'
      open={props.isOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      maskClosable={false}
      cancelText="取消"
      okText="保存">

      <MyInput
        id="title"
        required
        autoFocus
        maxLength={64}
        label='标题'
        placeholder='请输入问题的标题'
        value={formValue.title}
        onChange={setFormValue}
        errorMsg={errorMsg.titleEmpty}
      />

      <MyTextArea
        id="description"
        label='描述'
        minRows={2}
        placeholder='请输入问题的描述'
        value={formValue.description}
        onChange={setFormValue}
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />

      <MySwitch 
        id='is_required'
        label='用户必填'
        checked={formValue.is_required}
        onChange={setFormValue}
      />

      <MyRadio
        id='question_type'
        label='问题类型'
        options={[
          {
            label: '单行',
            value: 1,
          },
          {
            label: '多行',
            value: 2,
          },
          {
            label: '单选',
            value: 3,
          },
          {
            label: '多选',
            value: 4,
          },
          {
            label: '列表',
            value: 5,
          },
          {
            label: '地址',
            value: 6,
          },
          {
            label: '图片',
            value: 7,
          }
        ]}
        defaultValue={1}
        value={formValue.question_type}
        onChange={setFormValue}
        errorMsg={errorMsg.optionsEmpty}
      />

      {(!formValue.question_type || formValue.question_type === 1) && 
        <MyRadio
          id='text_check'
          label='单行内容校验'
          options={[
            {
              label: '不校验',
              value: 1,
            },
            {
              label: '手机号',
              value: 2,
            },
            {
              label: '身份证号',
              value: 3,
            }
          ]}
          defaultValue={1}
          value={formValue.text_check}
          onChange={setFormValue}
        />
      }

      {[3, 4, 5].includes(formValue.question_type) && (
        <div className='forms-collect-add-options'>
          {options.map((option, index) => (
            <div  className='forms-collect-add-option' key={option.sort}>
              <input
                type="text"
                placeholder={`选项 ${index + 1}`}
                value={option.value}
                onChange={(e) => handleOptionChange(option.sort, e.target.value)}
              />
              <div className='forms-collect-add-option-delete' onClick={() => handleRemoveOption(option.sort)}>
                <MyIcon name='huishouzhan' />
              </div>
            </div>
          ))}
          <button type="button" onClick={handleAddOption}>添加选项</button>
        </div>
      )}

      {formValue.question_type === 6 && 
        <MyRadio
          id='region_level'
          label='地理位置层级'
          options={[
            {
              label: '省/市',
              value: 1,
            },
            {
              label: '省/市/区',
              value: 2,
            },
            {
              label: '省/市/区+详细地址',
              value: 3,
            }
          ]}
          defaultValue={1}
          value={formValue.region_level}
          onChange={setFormValue}
        />
      }

    </Modal>
  )
}

export default EditCollectQuestion
